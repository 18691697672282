import styles from '../../styles/components/blocks/downloads.module.scss';
import type { DownloadItem } from '../../types/blocks/download-item';
import type { Attributes } from '../../types/blocks/attributes';
import Link from 'next/link';

interface Props {
  downloads?: Array<DownloadItem>;
  attributes?: Attributes;
  narrow?: Boolean;
}

export default function Downloads({
  downloads,
  attributes,
  narrow
}: Props): JSX.Element {

  const Download = function(
    item: DownloadItem, 
    key: number): JSX.Element {
      return (
        (<Link
          key={key}
          href={item.download.mediaItemUrl}
          className={`${styles.downloads__button} btn primary download`}
          target="_blank">

          <span>{item.buttonText}</span>

        </Link>)
      );
  };

  return (
    <div id={attributes?.anchor} className="container">
      <div className={`
        ${styles.downloads__wrapper}
        ${narrow ? styles.narrow : ''}
      `}>
        <div className={styles.downloads__title}>
          Downloads
        </div>
        {downloads?.map(Download)}
      </div>
    </div>
  );
}
