import classNames from 'classnames';
import type { Block } from '../types/api';
import parse from 'html-react-parser';
import styles from '../styles/components/blocks.module.scss';

import Testimonial from './blocks/Testimonial';
import IconTitleTextList from './blocks/IconTitleTextList';
import TwoColImageText from './blocks/TwoColImageText';
import SlimLineImageText from './blocks/SlimLineImageText';
import Newsletter from './blocks/Newsletter';
import AbixNewsletter from './blocks/AbixNewsletter';
import Button from './blocks/Button';
import BlockQuote from './blocks/BlockQuote';
import RequestACallbackButton from './blocks/RequestACallbackButton';
import Accordion from './blocks/Accordion';
import PageText from './blocks/PageText';
import Downloads from './blocks/Downloads';
import FullWidthTileWrapper from './blocks/FullWidthTileWrapper';
import FullWidthCallout from './blocks/FullWidthCallout';
import PromoBanner from './blocks/PromoBanner';
import HeroBanner from './blocks/HeroBanner';
import ContentHighlightTiles from './blocks/ContentHighlightTiles';
import Timeline from './blocks/Timeline';
import MorganPollChart from './blocks/MorganPollChart';
import Offices from './blocks/Offices';
import GravityForm from './blocks/GravityForm';

interface Props {
  blocks?: Array<Block>;
  fullWidth?: boolean;
  narrow?: boolean;
  className?: string;
}

export default function Blocks({ blocks, fullWidth = false, narrow = false, className }: Props): JSX.Element | null {
  if (!blocks) {
    return null;
  }

  return (
    <div className={classNames(styles.root, { fullWidth: fullWidth }, { narrow: narrow }, className)}>
      {blocks.map(function (block, i) {
        switch (block.name) {
          case 'acf/two-col-image-text':
            return (
              <TwoColImageText
                key={i}
                attributes={block?.attributes}
                title={block?.TwoColImageText?.title || ''}
                content={block?.TwoColImageText?.content || ''}
                image={block?.TwoColImageText?.image}
                click_through_text={block?.TwoColImageText?.clickThroughText || ''}
                click_through_link={block?.TwoColImageText?.clickThroughLink || ''}
                red_bar_top={block?.TwoColImageText?.redBarTop || false}
                image_right={block?.TwoColImageText?.imageRight || false}
              />
            );
          case 'acf/slim-line-image-text':
            return (
              <SlimLineImageText
                key={i}
                attributes={block?.attributes}
                title={block?.SlimLineImageText?.title || ''}
                content={block?.SlimLineImageText?.content || ''}
                image={block?.SlimLineImageText?.image}
                click_through_text={block?.SlimLineImageText?.clickThroughText || ''}
                click_through_link={block?.SlimLineImageText?.clickThroughLink || ''}
                click_through_is_button={block?.SlimLineImageText?.clickThroughIsButton || false}
                click_through_open_new_tab={block?.SlimLineImageText?.clickThroughOpenNewTab || false}
                click_through_position_top={block?.SlimLineImageText?.clickThroughPositionTop || false}
                image_right={block?.SlimLineImageText?.imageRight || false}
              />
            );
          case 'acf/icon-title-text-list':
            return <IconTitleTextList key={i} attributes={block?.attributes} items={block?.IconTitleText?.items} />;
          case 'acf/testimonial':
            return (
              <Testimonial
                key={i}
                attributes={block?.attributes}
                image={block?.Testimonial?.image}
                quote={block?.Testimonial?.quote || ''}
                quote_source={block?.Testimonial?.quoteSource}
                quote_description={block?.Testimonial?.quoteDescription}
                click_through_link={block?.Testimonial?.clickThroughLink}
                full_width={block?.Testimonial?.fullWidth}
              />
            );
          case 'acf/newsletter':
            return <Newsletter key={i} attributes={block?.attributes} title={block?.Newsletter?.title} content={block?.Newsletter?.content} />;
          case 'acf/abix-newsletter':
            return <AbixNewsletter key={i} attributes={block?.attributes} title={block?.AbixNewsletter?.title} buttonText={block?.AbixNewsletter?.buttonText} />;
          case 'acf/block-quote':
            return <BlockQuote key={i} content={block?.BlockQuote?.content} attributes={block?.attributes} />;
          case 'acf/button':
            return <Button key={i} title={block?.Button?.link?.title} target={block?.Button?.link?.target} url={block?.Button?.link?.url} />;
          case 'acf/content-highlight-tiles':
            return (
              <ContentHighlightTiles
                key={i}
                attributes={block?.attributes}
                tiles={block?.ContentHighlightTiles?.tiles}
                title={block?.ContentHighlightTiles?.title}
                view_all_link={block?.ContentHighlightTiles?.viewAllLink}
                view_all_text={block?.ContentHighlightTiles?.viewAllText}
              />
            );
          case 'acf/request-a-callback-button':
            return (
              <RequestACallbackButton
                key={i}
                attributes={block?.attributes}
                title={block?.RequestACallback?.title}
                content={block?.RequestACallback?.content}
                show_icon={block?.RequestACallback?.showIcon}
                button_text={block?.RequestACallback?.buttonText}
                modal_title={block?.RequestACallback?.modalTitle}
                modal_text={block?.RequestACallback?.modalText}
                modal_form_id={block?.RequestACallback?.modalFormId}
                display_as_full_width={block?.RequestACallback?.displayAsFullWidth}
              />
            );
          case 'acf/full-width-callout':
            return (
              <FullWidthCallout
                key={i}
                attributes={block?.attributes}
                title={block?.FullWidthCallout?.title}
                content={block?.FullWidthCallout?.content}
                show_icon={block?.FullWidthCallout?.showIcon}
                button_text={block?.FullWidthCallout?.buttonText}
                button_link={block?.FullWidthCallout?.buttonLink}
              />
            );
          case 'acf/promo-banner':
            return (
              <PromoBanner
                key={i}
                attributes={block?.attributes}
                background_image={block?.PromoBanner?.backgroundImage}
                background_image_mobile={block?.PromoBanner?.backgroundImageMobile}
                button_type={block?.PromoBanner?.buttonType}
                button_text={block?.PromoBanner?.buttonText}
                button_link={block?.PromoBanner?.buttonLink}
                title={block?.PromoBanner?.title}
              />
            );
          case 'acf/hero-banner':
            return (
              <HeroBanner
                key={i}
                slides={block?.HeroBanner?.slides}
                attributes={block?.attributes}
                autoSlideDuration={block?.HeroBanner?.autoSlideDuration}
                autoSlide={block?.HeroBanner?.autoSlide}
              />
            );
          case 'acf/accordion':
            return <Accordion key={i} title={block?.Accordion?.title} items={block?.Accordion?.items} attributes={block?.attributes} narrow={narrow} />;
          case 'acf/page-text':
            return <PageText key={i} introduction={block?.PageText?.introduction} content={block?.PageText?.content} attributes={block?.attributes} />;
          case 'acf/downloads':
            return <Downloads key={i} downloads={block?.Downloads?.downloads} attributes={block?.attributes} narrow={narrow} />;
          case 'acf/offices':
            return <Offices key={i} offices={block?.Offices?.offices.officesGroup} attributes={block?.attributes} />;
          case 'acf/gravity-form':
            return <GravityForm key={i} formId={block?.GravityForm?.gravityFormId} attributes={block?.attributes} noMargins={block?.noMargins} />;
          case 'acf/morgan-poll-charts':
            return (
              <MorganPollChart
                key={i}
                attributes={block?.attributes}
                title={block?.MorganPollCharts?.title}
                col1Title={block?.MorganPollCharts?.col1Title}
                col1SubTitle={block?.MorganPollCharts?.col1SubTitle}
                col1GraphTitle1={block?.MorganPollCharts?.col1GraphTitle1}
                col1GraphTitle1Color={block?.MorganPollCharts?.col1GraphTitle1Color}
                col1GraphText1={block?.MorganPollCharts?.col1GraphText1}
                col1GraphText2={block?.MorganPollCharts?.col1GraphText2}
                col1GraphTitle2Color={block?.MorganPollCharts?.col1GraphTitle2Color}
                col1GraphTitle2={block?.MorganPollCharts?.col1GraphTitle2}
                col1GraphValue1={block?.MorganPollCharts?.col1GraphValue1}
                col1GraphValue2={block?.MorganPollCharts?.col1GraphValue2}
                col2Title={block?.MorganPollCharts?.col2Title}
                col2SubTitle={block?.MorganPollCharts?.col2SubTitle}
                col2GraphValue4={block?.MorganPollCharts?.col2GraphValue4}
                col2GraphValue3={block?.MorganPollCharts?.col2GraphValue3}
                col2GraphValue2={block?.MorganPollCharts?.col2GraphValue2}
                col2GraphValue1={block?.MorganPollCharts?.col2GraphValue1}
                col2GraphTitle4Color={block?.MorganPollCharts?.col2GraphTitle4Color}
                col2GraphTitle4={block?.MorganPollCharts?.col2GraphTitle4}
                col2GraphTitle3Color={block?.MorganPollCharts?.col2GraphTitle3Color}
                col2GraphTitle3={block?.MorganPollCharts?.col2GraphTitle3}
                col2GraphTitle2Color={block?.MorganPollCharts?.col2GraphTitle2Color}
                col2GraphTitle2={block?.MorganPollCharts?.col2GraphTitle2}
                col2GraphTitle1Color={block?.MorganPollCharts?.col2GraphTitle1Color}
                col2GraphTitle1={block?.MorganPollCharts?.col2GraphTitle1}
                col3GraphBottomLineChartValues={block?.MorganPollCharts?.col3GraphBottomLineChartValues}
                col3GraphBottomSubTitle={block?.MorganPollCharts?.col3GraphBottomSubTitle}
                col3GraphBottomTitle={block?.MorganPollCharts?.col3GraphBottomTitle}
                col3GraphBottomValue1={block?.MorganPollCharts?.col3GraphBottomValue1}
                col3GraphBottomValue2={block?.MorganPollCharts?.col3GraphBottomValue2}
                col3GraphBottomValue2Color={block?.MorganPollCharts?.col3GraphBottomValue2Color}
                col3GraphTopLineChartValues={block?.MorganPollCharts?.col3GraphTopLineChartValues}
                col3GraphTopSubTitle={block?.MorganPollCharts?.col3GraphTopSubTitle}
                col3GraphTopTitle={block?.MorganPollCharts?.col3GraphTopTitle}
                col3GraphTopValue1={block?.MorganPollCharts?.col3GraphTopValue1}
                col3GraphTopValue2={block?.MorganPollCharts?.col3GraphTopValue2}
                col3GraphTopValue2Color={block?.MorganPollCharts?.col3GraphTopValue2Color}
                col4GraphBottomLineChartValues={block?.MorganPollCharts?.col4GraphBottomLineChartValues}
                col4GraphBottomValue2Color={block?.MorganPollCharts?.col4GraphBottomValue2Color}
                col4GraphBottomValue2={block?.MorganPollCharts?.col4GraphBottomValue2}
                col4GraphBottomValue1={block?.MorganPollCharts?.col4GraphBottomValue1}
                col4GraphBottomTitle={block?.MorganPollCharts?.col4GraphBottomTitle}
                col4GraphBottomSubTitle={block?.MorganPollCharts?.col4GraphBottomSubTitle}
                col4GraphTopLineChartValues={block?.MorganPollCharts?.col4GraphTopLineChartValues}
                col4GraphTopSubTitle={block?.MorganPollCharts?.col4GraphTopSubTitle}
                col4GraphTopTitle={block?.MorganPollCharts?.col4GraphTopTitle}
                col4GraphTopValue1={block?.MorganPollCharts?.col4GraphTopValue1}
                col4GraphTopValue2={block?.MorganPollCharts?.col4GraphTopValue2}
                col4GraphTopValue2Color={block?.MorganPollCharts?.col4GraphTopValue2Color}
              />
            );
          case 'acf/full-width-tile':
            return (
              <FullWidthTileWrapper
                key={i}
                attributes={block?.attributes}
                content={block?.FullWidthTile?.content}
                title={block?.FullWidthTile?.title}
                link={block?.FullWidthTile?.link}
                linkText={block?.FullWidthTile?.linkText}
                tileImage={block?.FullWidthTile?.tileImage}
              />
            );
          case 'acf/wysiwyg':
            return (
              <div
                className="wysiwyg__wrapper"
                key={i}
                id={block?.attributes?.anchor}
                dangerouslySetInnerHTML={{
                  __html: block?.wysiwyg?.content ? block?.wysiwyg?.content : '',
                }}
              />
            );
          case 'acf/raw-html':
            return (
              <div
                key={i}
                id={block?.attributes?.anchor}
                className={styles.block_raw_html}
                dangerouslySetInnerHTML={{
                  __html: block?.RawHtml?.content ? block?.RawHtml?.content : '',
                }}
              />
            );
          case 'acf/timeline':
            return <Timeline key={i} title={block?.Timeline?.title} sections={block?.Timeline?.sections} footnotes={block?.Timeline?.footnotes} attributes={block?.attributes} />;
          case 'core/list':
            if (block.saveContent) {
              return (
                <div id={block?.attributes?.anchor} key={i} className={styles.block_default_content}>
                  {parse(block.saveContent)}
                </div>
              );
            }
          default:
            if (block && block.dynamicContent) {
              return (
                <div id={block?.attributes?.anchor} key={i} className={styles.block_default_content}>
                  {parse(block.dynamicContent)}
                </div>
              );
            }
            if (block.originalContent) {
              return (
                <div id={block?.attributes?.anchor} key={i} className={styles.block_default_content}>
                  {parse(block.originalContent)}
                </div>
              );
            }
        }
      })}
    </div>
  );
}
